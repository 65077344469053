import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";
import { NACIONALIDADES, PROFESIONES, ESTADO_CIVIL } from "../constants/lasegunda";
import orderAlphabetically from "../utils/alphabeticalOrder";

// Compañias
export const getCompanias = async () => {
    const companias = await axiosRequest.get(`/companias`);
    // FILTRAR LA SEGUNDA
    //const filterCompanias = companias.data.filter(compania => compania.id_compania === 19)
    const companiasList = formatSelectData(
        companias.data,
        "id_compania",
        "nombre_compania",
        "id_compania"
      );
    
    return companiasList
}

export const getCompaniasByName = async () => {
  const companias = await axiosRequest.get(`/companias`);
  // FILTRAR LA SEGUNDA
  //const filterCompanias = companias.data.filter(compania => compania.id_compania === 19)
  const companiasList = formatSelectData(
      companias.data,
      "nombre_compania",
      "nombre_compania",
      "id_compania"
    );
  
  return companiasList
}

// Productos
export const getProductos = async (compania) => {
    const productos = await axiosRequest.get(`/productos_cias/${compania}`);
    return productos.data
}

// Coberturas
export const getCoberturas = async (compania, producto) => {
    console.log(compania)
    console.log(producto)
    const coberturas = await axiosRequest.get(`/coberturas_productos/${compania}/${producto}`);
    console.log(coberturas)
    const coberturasList = formatSelectData(
        coberturas.data,
        "id_cobertura",
        "nombre_cobertura",
        "id_producto_cia"
      );
    return coberturasList
}

// Vigencias
export const getVigencias = async () => {
    const coberturas = await axiosRequest.get(`/vigencias`);
    const coberturasList = formatSelectData(
        coberturas.data,
        "descripcion_vigencia",
        "descripcion_vigencia",
        "descripcion_vigencia"
      );
    return coberturasList
}

// Campos
export const getCamposCobertura = async (id_producto_cia) => {
  /*
    const coberturaData = await axiosRequest.get(`/campos_coberturas/${compania}/${producto}`);
    console.log(coberturaData)
    const id_producto_cia = coberturaData.data.id_producto_cia
    */
    const campos = await axiosRequest.get(`/campos_coberturas_productos/${id_producto_cia}`);
    return campos.data
}

// Medios de Pago
export const getMediosPago = async (id_compania, id_producto) => {
    const mediosPago = await axiosRequest.get(`/medios_pagos_compania/${id_compania}/${id_producto}`);
    const mediosPagoList = formatSelectData(
        mediosPago.data,
        "id_medio_pago",
        "descripcion_medio_pago",
        "id_medio_pago"
      );
    return mediosPagoList
}

// Aclaraciones
export const getAclaraciones = async () => {
  const aclaraciones = await axiosRequest.get(`/aclaraciones`);
  return aclaraciones.data
}

// Provincias
export const getProvincias = async () => {
  const provincias = await axiosRequest.get("/provincias");
  const provinciasList = provincias.data.map((provincia, index) => {
      return {
          'label': provincia.provincia_nombre,
          'value': provincia.provincia_nombre,
          'key': index + 1
      }
  })
  return provinciasList
}

// Localidades
export const getLocalidades = async (provincia) => {
  console.log(provincia)
  const localidades = await axiosRequest.get(`/localidades/${provincia}`)
  const localidadesList = formatSelectData(
    localidades.data,
    "localidad_nombre",
    "localidad_nombre",
    "localidad_nombre"
  );
  const otrosOption = {
    'label': 'OTROS',
    'value': 'Otros',
    'key': 1
  }
  let allLocalidades = [...localidadesList, otrosOption]
  return allLocalidades.sort((a, b) => a.value.localeCompare(b.value));
}

// Nacionalidades
export const getNacionalidades = () => {
  const nacionalidadesList = NACIONALIDADES.map((nacionalidad) => {
    return {
        'label': nacionalidad.descripcion,
        'value': nacionalidad.descripcion,
        'key': nacionalidad.codigo
    }
  })
  return nacionalidadesList
  /*
  const nacionalidades = await axiosRequest.get('/nacionalidades')
  const nacionalidadesList = nacionalidades.data.map((nacionalidad) => {
    return {
        'label': nacionalidad.descripcion,
        'value': nacionalidad.descripcion,
        'key': nacionalidad.codigo
    }
  })
  return nacionalidadesList
  */
}

// Profesiones
export const getProfesiones = () => {
  const profesionesList = PROFESIONES.map((profesion, index) => {
    return {
        'label': profesion.descripcion,
        'value': profesion.descripcion,
        'key': profesion.codigo
    }
  })
  const profesionesOrder = profesionesList.sort(orderAlphabetically)
  return profesionesOrder
  /*
  const profesiones = await axiosRequest.get('/profesiones');
  const profesionesList = profesiones.data.map((profesion, index) => {
    return {
        'label': profesion.descripcion_profesion,
        'value': profesion.descripcion_profesion,
        'key': index + 1
    }
  })
  return profesionesList
  */
}

// Estados Civil
export const getEstadosCivil = () => {
  const estadosCivilList = ESTADO_CIVIL.map((estado_civil, index) => {
    return {
        'label': estado_civil.descripcion,
        'value': estado_civil.descripcion,
        'key': estado_civil.codigo
    }
  })
  const estadosCivilesOrder = estadosCivilList.sort(orderAlphabetically)
  return estadosCivilesOrder
  /*
  const estadosCivil = await axiosRequest.get('/estado_civil')
  const estadosCivilList = estadosCivil.data.map((estado_civil, index) => {
    return {
        'label': estado_civil.descripcion_estado_civil,
        'value': estado_civil.descripcion_estado_civil,
        'key': index + 1
    }
  })
  return estadosCivilList
  */
}

// Buscar asegurado por DNI
export const getAseguradoByDNI = async (dni) => {
  const asegurado = await axiosRequest.get(`/asegurado_nombre/${dni}`)
  console.log(asegurado.data)
  return asegurado.data
}

// Postear data de asegurado (form)
export const postAseguradoData = async (asegurado) => {
  const aseguradoForm = await axiosRequest.post('/asegurados', asegurado)
  if(aseguradoForm.status === 200){
    return {
        id_asegurado: aseguradoForm.data.id,
        id_asegurado_adicional: null
    }
  }
}

// Postear data de asegurado (form)
export const postAseguradoAdicionalData = async (asegurado) => {
  const aseguradoForm = await axiosRequest.post('/asegurados_adicionales', asegurado)
  if(aseguradoForm.status === 200){
    return {
        id_asegurado: null,
        id_asegurado_adicional: aseguradoForm.data.id
    }
  }
}

// Postear data de asegurado (form)
export const updateAseguradoData = async (asegurado, id_asegurado) => {
  const aseguradoForm = await axiosRequest.put(`/asegurados/${id_asegurado}`, asegurado)
  return aseguradoForm.status
}

// Postear data de asegurado (form)
export const updateAseguradoAdicionalData = async (asegurado, id_asegurado_adicional) => {
  const aseguradoForm = await axiosRequest.put(`/asegurados_adicionales/${id_asegurado_adicional}`, asegurado)
  return aseguradoForm.status
}

// Postear data de asegurado (form)
export const eliminarAsegurado = async (asegurado) => {
  const aseguradoData = await axiosRequest.delete(`/asegurados/${asegurado}`)
  return aseguradoData.data
}

// Postear data de seguro (form)
export const postSeguroData = async (seguro) => {
  const seguroForm = await axiosRequest.post('/coberturas_aseguradas', seguro)
  if(seguroForm.status === 200){
    return {
      coberturaID: seguroForm.data.id
    }
  }
}

// Postear data de seguro (form)
export const eliminarSeguro = async (seguro) => {
  const seguroForm = await axiosRequest.delete(`/coberturas_aseguradas/${seguro}`)
  return seguroForm.data
}

// Postear data de asegurado (form)
export const updateSeguroData = async (seguro, id_cobertura_asegurada) => {
  const seguroForm = await axiosRequest.put(`/coberturas_aseguradas/${id_cobertura_asegurada}`, seguro)
  return seguroForm.status
}

// Postear data de bien asegurado (form)
export const postBienAseguradoData = async (bienAsegurado) => {
  const bienAseguradoCampo = await axiosRequest.post('/bienes_asegurados', bienAsegurado)
  return bienAseguradoCampo.data
}

// Postear data de bien asegurado (form)
export const eliminarBienAsegurado = async (bienAsegurado) => {
  const bienAseguradoCampo = await axiosRequest.delete(`/bienes_asegurados/${bienAsegurado}`)
  return bienAseguradoCampo.data
}

// Postear data de pago (form)
export const postPagoData = async (pago) => {
  const pagoForm = await axiosRequest.post('/datos_facturacion', pago)
  return {
    facturacionID: pagoForm.data.id
  }
}

// Postear data de pago (form)
export const eliminarPago = async (pago) => {
  const pagoForm = await axiosRequest.delete(`/datos_facturacion/${pago}`)
  return pagoForm.data
}

// Postear mensaje historial
export const postMensajeHistorial = async (mensaje) => {
  const mensajeHistorial = await axiosRequest.post('/solicitudes_historial', mensaje)
  return mensajeHistorial.data
}

// Postear solicitud
export const postSolicitud = async (solicitud) => {
  const solicitudData = await axiosRequest.post('/solicitudes', solicitud)
  if(solicitudData.status == 200){
    return {
      solicitudID: solicitudData.data.id,
    };
  }
}

// Get solicitud
export const getSolicitud = async (id) => {
  const solicitudData = await axiosRequest.get(`/solicitudes/${id}`)
  return solicitudData.data
}

// Eliminar solicitud
export const eliminarSolicitud = async (id) => {
  const solicitudData = await axiosRequest.delete(`/solicitudes/${id}`)
  return solicitudData.data
}

// Motivos
export const getMotivosRechazo = async () => {
  const motivosRechazo = await axiosRequest.get(`/motivos`);
  const motivosRechazoList = formatSelectData(
    motivosRechazo.data,
    "id_motivo",
    "nombre_motivo",
    "id_motivo"
  )
  return motivosRechazoList
}

// Actualizar solicitud  
export const actualizarSolicitud = async (id, data) => {
    const solicitudUpdate = await axiosRequest.put(`/solicitudes/${id}`, data);
    return solicitudUpdate.data
}

// Actualizar solicitud  
export const actualizarSolicitudEstado = async (id, data) => {
  const solicitudUpdate = await axiosRequest.put(`/solicitudes_estado/${id}`, data);
  return solicitudUpdate.data
}

// --------------- PÁGINA SOLICITUD DE EMISIÓN --------------- //

// Get solicitud
export const getSolicitudes = async (filterParams, orderParams, page, userParams) => {
  const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
  const solicitudData = await axiosRequest.get(`/solicitudes_new?${pageFilter}${filterParams}&${orderParams}&${userParams}`)
  return solicitudData.data
}

export const getSolicitudesEmitidas = async (filterParams, orderParams, page, userParams) => {
  const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
  const solicitudData = await axiosRequest.get(`/estadosolicitudes/2?${pageFilter}${filterParams}&${orderParams}&${userParams}`)
  return solicitudData.data
}

export const getSolicitudesPorUsuario = async (filterParams, orderParams, page, usuarioId) => {
  const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
  const solicitudData = await axiosRequest.get(`/solicitudes_new/${usuarioId}?${pageFilter}${filterParams}&${orderParams}`)
  return solicitudData.data
}

export const getVendedores = async () => {
  const vendedores = await axiosRequest.get(`/operadores_rol/10`)
  const vendedoresList = formatSelectData(vendedores.data, ['nombre_operador', 'apellido_operador'], ['nombre_operador', 'apellido_operador'], 'id_operador')
  return vendedoresList
}

export const getOperadores = async () => {
  const operadoresEmision = await axiosRequest.get(`/operadores_rol/6`);
  const operadoresAtencionCliente = await axiosRequest.get(`/operadores_rol/7`);
  const operadoresComercial = await axiosRequest.get(`/operadores_rol/1`);
  const supervisorComercial = operadoresComercial.data.find(op => op.usuario_usuario === "lmareque@gyssrl.com.ar")
  const operadores = [...operadoresEmision.data, ...operadoresAtencionCliente.data, supervisorComercial]
  const operadoresFormatData = formatSelectData(operadores, ['nombre_operador', 'apellido_operador'], ['nombre_operador', 'apellido_operador'], 'id_operador')
  const operadoresListIdsFormat = formatSelectData(operadores, 'id_operador',  ['nombre_operador', 'apellido_operador'], 'id_operador')
  return {
    operadores: operadoresFormatData,
    operadoresIds: operadoresListIdsFormat
  }
}

// Actualizar solicitud  
export const getTipoSolicitudes = async () => {
  const tipo_solicitud = await axiosRequest.get(`/tipos_solicitudes`)
  const tiposSolicitudesList = formatSelectData(
    tipo_solicitud.data,
    "id_tipo_solicitud",
    "nombre",
    "id_tipo_solicitud"
  )
  return tiposSolicitudesList
}

// Actualizar solicitud  
export const getEstadosEmision = async (vendedor) => {
  const estados = await axiosRequest.get(`/estados`)
  const filterEstados = vendedor ? estados.data : estados.data.filter(estado => { return estado.nombre_estado !== "Emitido"})
  const formatEstados = formatSelectData(filterEstados, 'nombre_estado', 'nombre_estado', 'id_estado')
  return formatEstados
}

// Postear mensaje historial
export const postSolicitudPoliza = async (poliza) => {
  const solicitudPoliza = await axiosRequest.post('/solicitudes_polizas', poliza)
  return solicitudPoliza.data
}

// Postear mensaje historial
export const postSolicitudCertificado = async (certificado) => {
  const solicitudCertificado = await axiosRequest.post('/solicitudes_certificados', certificado)
  return solicitudCertificado.data
}

// Actualizar operacion (sw_operaciones en novedades)
export const actualizarOperacion = async (operacion) => {
  const actualizarOp = await axiosRequest.put('/emitir_operacion', { operacion: operacion })
  return actualizarOp.data
}