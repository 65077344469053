import { DivApp, EmisionData, ModalContainerEmision } from "../../styled.js";
import {
  Grid,
  Col
} from "../../../components/Index/index.js";
import Sidebar from "../../../components/Sidebar/index.js";
import { useHistory } from "react-router-dom";
import SpinnerLoading from "../../../components/SpinnerLoading/index.js";
import Estado from "../../../components/Estado/index.js";
import { ContainerEstados, ContainerFile, ContainerHistorial, EmptyDocumentacion, ModificacionHistorial, Prioridad } from "./styled.js";
import {
  FiUser,
  FiArrowLeft,
  FiX,
  FiZap,
  FiXOctagon,
  FiCheck,
  FiMail,
  FiPhone
} from "react-icons/fi";
import NewButton from "../../../components/NewButton/index.jsx";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewTitle from "../../../components/NewTitle/index.jsx";
import { useEffect } from "react";
import useRechazoV2 from "../../../hooks/useRechazosV2.js";
import EmisionSeccion from "../../../components/EmisionSeccion";
import { useTabs } from "../../../hooks/useTabs.js";
import ModalGestionarRechazo from "../../../components/Modals/Rechazos/ModalGestionarRechazos/index.js";
import useModal from "../../../hooks/useModal.js";
import { getEstados } from "../../../services/Rechazos.js";
import { useState } from "react";
import dayjs from "dayjs";
import { meses } from "../../../constants/data.js";
import Divider from "../../../components/Divider/index.js";
import HeaderTitleSection from "../../../components/HeaderTitleSection/index.js";
import MediosContactoSeccion from "../../../components/MediosContactoSeccion/index.js";
import ModalNotificar from "../../../components/Modals/Rechazos/ModalNotificar/index.js";
import { HiOutlineMailOpen } from "react-icons/hi";

const RechazoV2 = () => {
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const { tabSelected, tabName, handleTab } = useTabs();
  // Hook de router
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  //const { data, loading, pagination } = useEmisiones(params, order)
  const { rechazo, historial, loading, sendOneMail, getHistorial, getRechazo, success, handleSuccess } = useRechazoV2()
  const [estados, setEstados] = useState([])
  const modal = useModal()
  const modalNotificar = useModal()

  const estadosQueGestionan = [12, 11, 8] //promesa, notificado, pendiente

  const speeches = {
    0: `¡Hola ${rechazo?.nombre}! Nos comunicamos desde SeguroWeb. Queremos decirte que la última cuota de tu póliza ${rechazo?.poliza} de la Compañía ${rechazo?.compania}, está impaga y se encuentra sin cobertura. El importe es de $${rechazo?.importe}. Sabemos que estar protegido es muy importante para vos. Para poder regularizar tu seguro responde este mensaje. Estamos para ayudarte.`,
    1: `¡Hola ${rechazo?.nombre}! Queremos recordarte que la última cuota del mes de ${meses[parseInt(rechazo?.createdAt.split('/')[1])-1]} de tu póliza ${rechazo?.poliza} de la Compañía ${rechazo?.compania} ¡Está impaga y se encuentra sin cobertura! El importe es de $${rechazo?.importe} ¡Es muy importante que abones para que tu bien esté asegurado! Para poder regularizar tu seguro respondé este mensaje. Estamos para ayudarte.`,
    2: `¡Hola ${rechazo?.nombre}! Te recordamos que tu póliza ${rechazo?.poliza} continúa manteniendo un saldo pendiente de pago y se encuentra sin cobertura. Te sugerimos abonarlo para evitar la cancelación de tu póliza y así recuperes la protección. Queremos ayudarte a que te sea fácil abonarlo. Sí tenés alguna dificultad ¿Querés que te llamemos?`
  }
  const textWhatsApp = `&text=${speeches[rechazo?.veces_notificado_whatsapp]}`

  useEffect(() => {
    const getEstadosRechazos = async () => {
      try {
        const estados = await getEstados()
        setEstados(estados)
      } catch (error) {
        console.log(error)
      }
    }
    getEstadosRechazos()
  }, [])

  const userLabels = {
    nombre: "Nombre",
    dni: "DNI",
  }

  const contactoLabels = {
    mail: "Correo",
    telefono: "Teléfono"

  }

  const rechazoLabels = {
    id: "Número",
    createdAt: "Fecha de Ingreso",
    updatedAt: "Ú. modificación",
    compania: "Compañía",
    poliza: "Póliza",
    seccion: "Ramo",
    medio_pago: "Medio de Pago",
    importe: "Importe"
  }

  const handleBack = () => history.goBack();

  let tabs = [
    {
      name: "Rechazo",
    },
    {
      name: "Historial",
    }
  ];

  const handleNotificarModal = () => {
    modalNotificar.setIsModalOpen(true)
    sendOneMail()
  }


  return (
    <DivApp className="App">
      <ModalGestionarRechazo modal={modal} estados={estados} rechazo={rechazo} getHistorial={getHistorial} getRechazo={getRechazo} />
      <ModalNotificar modal={modalNotificar} loading={loading} success={success} handleSuccess={handleSuccess} />
      <Sidebar />
      <ModalContainerEmision>
        <div>
          {!loading ? (
            <>
            <div>
            {rechazo?.veces_notificado + rechazo?.veces_notificado_telefono + rechazo?.veces_notificado_whatsapp > 0 &&
                <Col desktop={12}>
                  <Prioridad>Veces Notificado: { rechazo?.veces_notificado + rechazo?.veces_notificado_telefono + rechazo?.veces_notificado_whatsapp }</Prioridad>
                </Col>
            }
             <Col desktop={12}>
                  <ContainerEstados>
                    <span className="header-estado">
                      Rechazo: {rechazo?.id}
                    </span>
                    <span>
                      ・ Estado del Rechazo:{" "}
                      <Estado
                        estado={rechazo?.Rejects_estado.estado}
                        operador={null}
                        previo={null}
                      />
                    </span>
                    {rechazo?.arrastre === 1 &&
                        <span className={"arrastre"}>
                          <span className="text_arrastre"> ARRASTRE </span>
                        </span>
                    }

                  </ContainerEstados>
                </Col>
                <Col desktop={12}>
                  <NewTitle textColor={false}>
                    {rechazo?.seccion} - {rechazo?.nombre}{" "}
                  </NewTitle>
                </Col>
                <HeaderTitleSection compania={rechazo?.compania} estado={rechazo?.Rejects_estado.estado} />
              </div>
             <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {rechazo && tabSelected === 0 ?
                <>
                  <EmisionSeccion
                    title="Datos del Cliente"
                    data={rechazo}
                    label={userLabels}
                    icon={<FiUser />}
                  />
                  <MediosContactoSeccion
                    title="Datos de contacto"
                    data={rechazo}
                    label={contactoLabels}
                    icon={<FiMail />}
                    speech={textWhatsApp}
                  />
                  <EmisionSeccion
                    title="Datos del Rechazo"
                    data={rechazo}
                    label={rechazoLabels}
                    icon={<FiXOctagon />}
                  />
                </>
              : null}

              {tabSelected === 1 ?
                <ContainerHistorial>
                {historial.length > 0 && historial.map((item, index) => {
                  /*
                  const mesModificacion = dayjs(item.createdAt).format("M");
                  const mesModificacionAnterior =
                  index > 0 ? dayjs(historial[index - 1].createdAt).format("M") : "";
                  */

                  const diaModificacion = dayjs(item.createdAt).format("DD");
                  const diaModificacionAnterior =
                    index > 0 ? dayjs(historial[index - 1].createdAt).format("DD") : "";

                  return (
                    <>
                    {/*
                    {mesModificacion !== mesModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {
                        meses.find(
                          (mes) => mes.value === parseInt(mesModificacion - 1)
                        )?.label
                      }
                    </h4>
                  ) : null}
                   */}
                  {diaModificacion != diaModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {dayjs(item.createdAt).format("dddd D MMMM, YYYY")}
                    </h4>
                  ) : null}
                    <ModificacionHistorial>
                    <div className="icono">
                      <span>
                        {item.mailgun_status === "opened" && item.id_user === 66 ? <HiOutlineMailOpen color="#3d9f54" /> : null}
                        {item.mailgun_status === "failed" && item.id_user === 66 ? <FiX color="red" /> : null}
                        {!item.mailgun_status && item.id_user ? <FiUser /> : null}
                      </span>
                    </div>
                    <div className="modificacion">
                      <div className="modificacion-titulo">
                        {item.mailgun_status ? 
                          <span className="notificacion-sin-comentario">{item.comentario}</span>
                        :
                        <span>
                          <strong>{item.nombre_usuario} </strong> 
                          modificó el estado a <strong className={`estado ${item.estado.toLowerCase().replaceAll(" ", "-")}`}> {item.estado}</strong>. 
                          {item.via_contacto ? <> Comunicación con el asegurado mediante <strong className="whatsapp">{item.via_contacto.toLowerCase().replaceAll("_", " ")}</strong>. </> : null} 
                          
                          {item.veces_notificado ? <> El asegurado fue notificado {item.veces_notificado > 1 ? `${item.veces_notificado} veces.`  : `${item.veces_notificado} vez.`} </> : null}
                        </span>
                        }

                      </div>
                      {item.comentario && !item.mailgun_status ?
                      <div className="calculo-container">
                        <span>{item.comentario}</span>
                      </div>
                      : null}
                      <div>
                        <span className="modificacion-hora">
                          Modificación realizada a las{" "}
                          {dayjs(item.createdAt).format("HH:mm")}hs
                        </span>
                      </div>
                    </div>
                  </ModificacionHistorial>
                  {index + 1 !== historial.length ? <Divider /> : null}
                  </>
                  )
                })}
              </ContainerHistorial>
              : null}

              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <EmisionData apiDisabled={false}>
                    <div className={"actionButtonsCont"}>
                    <NewButton
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        direction={"before"}
                        onClick={handleBack}
                      >
                      <FiArrowLeft /> Volver
                    </NewButton>

                    <div className={"buttonsCont"}>
                    {
                        ( estadosQueGestionan.includes(rechazo?.id_estado))
                        &&
                        <>
                        <NewButton
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                          icon={false}
                          onClick={handleNotificarModal}
                        >
                          <FiZap /> Notificar
                        </NewButton>
                        <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={modal.handleModal}
                        >
                          <FiZap /> Gestionar rechazo
                      </NewButton>
                        </>
                        
                      }
                      </div>
                    </div>
                  </EmisionData>
                </Col>
              </Grid>
            </>
          ) : (
            <SpinnerLoading text="Cargando rechazo..." />
          )}
        </div>
      </ModalContainerEmision>
    </DivApp>
  );
};

export default RechazoV2;

